<template>
	<div class="twitter-timeline">
		<div v-if="title">{{ title }}</div>
		<a class="twitter-timeline" :href="`https://twitter.com/${username}?ref_src=twsrc%5Etfw`">Tweets by {{ username }}</a> 
	</div>
</template>

<script setup>
	import { onMounted } from "vue"

	const props = defineProps({
		username: String,
		title: String
	})

	onMounted(() => {
      const recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
      document.head.appendChild(recaptchaScript)
  })
</script>

<style scoped>
	.twitter-timeline {
		width: 100%;
	}
</style>
<template>
	<div @click="onClick">
        <div>
          {{ $t('trading_accounts_balance.available_amount') }}
        </div>
        <div style="display: flex;gap: 9px;justify-content: space-between;" v-if="balance" class="mt-2">
          <div style="font-size: 18px;">
            ${{ balance.balance }}
          </div>
          <div v-if="balance.canPayout"><UiButton to="/balance">
            {{ $t('trading_accounts_balance.request_payout') }}
          </UiButton></div>
        </div>
      </div>
      <UiModal :title="$t('trading_accounts_balance.modal.title')" v-if="showInfo" @close="showInfo = false">
        {{ $t('trading_accounts_balance.modal.content') }}
      </UiModal>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"

	const store = useStore()
  const showInfo = ref(false)

	store.dispatch("dashboard/getTradingBalance")

	const balance = computed(() => store.state.dashboard.tradingBalance)

  const onClick = () => {
    if (!balance.value.canPayout && !showInfo.value) showInfo.value = true
  }
</script>
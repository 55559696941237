<template>
	<!-- Back:Mobile -->
	<div class="mobile">
		<UiBack route="/settings" text="Back" fixed></UiBack>
	</div>
		
	<UiCard>
		<!-- Headline -->
		<UiHeadline class="mb-8" size="m">Configuration</UiHeadline>
		<Form :schema="configForm" v-model="configModel"></Form>

		<UiButton class="mt-8" @click="save">Save Settings</UiButton>
	</UiCard>
</template>

<script setup>
	import { ref, computed, watch } from "vue"
	import { useStore } from "vuex"

	const store = useStore()
	const configModel = ref({})

	const configForm = ref(`{
	  "rows": [
	  	{
	      "columns": [
	        {
	          "fields": [
	          	{	
	              "type": "headline",
	              "text": "Trading"
	            },
	            {
	              "type": "select_group",
	              "label": "Tradable Exchanges",
	              "name": "trading_exchanges",
	              "placeholder": "",
	              "required": false,
	              "options": [{"value": 1,"name": "CME"},{"value": 5,"name": "CBOT"},{"value": 9,"name": "NYMEX"},{"value": 7,"name": "COMEX"}]
	            }
	          ]
	        }
	      ]
	    }
	  ]
	}`)

	watch(() => configModel.value, () => {
		update()
	},{
		deep: true
	})

	configModel.value = store.state.dashboard.config

	const update = () => {
		store.dispatch('dashboard/updateConfig', configModel.value)
	}

	const save = () => {
		store.dispatch('user/updateConfig', configModel.value)
	}

	// {
	//       "columns": [
	//         {
	//           "fields": [
	//           	{	
	//               "type": "headline",
	//               "text": "Navigation"
	//             },
	//             {
	//               "type": "select_group",
	//               "label": "Show Times",
	//               "name": "navigation_clock_timezones",
	//               "placeholder": "",
	//               "required": false,
	//               "options": [{"value": "America/New_York","name": "New York"},{"value": "Europe/London","name": "London"},{"value": "Asia/Tokyo","name": "Tokio"}]
	//             }
	//           ]
	//         }
	//       ]
	//     },
	//     {
	//       "columns": [
	//         {
	//           "fields": [
	//           	{	
	//               "type": "headline",
	//               "text": "Theme"
	//             },
	//             {
	//               "type": "select",
	//               "label": "Set theme",
	//               "name": "theme_mode",
	//               "placeholder": "",
	//               "required": false,
	//               "options": [{"value": "light","label": "Default"},{"value": "darkmode","label": "Dark"}]
	//             }
	//           ]
	//         }
	//       ]
	//     },
	//     {
	//       "columns": [
	//         {
	//           "fields": [
	//           	{	
	//               "type": "headline",
	//               "text": "Pro Features"
	//             },
	//             {
	//               "type": "toggle_switch",
	//               "label": "Pro Navigation",
	//               "name": "pro_navigation_enabled",
	//               "placeholder": "",
	//               "required": false,
	//               "options": [{"value": true,"label": "Enabled"},{"value": false,"label": "Disabled"}]
	//             }
	//           ]
	//         }
	//       ]
	//     }
</script>

<style scoped>
	label {
		cursor: pointer;
	}
	input[type="file" i] {
		display: none;
	}
</style>
<template>
	<div class="card" :class="{'has-head':title}">
        <div class="card-head" @click="toggle" v-if="title">
            <UiHeadline size="m">
                {{ title }}
            </UiHeadline>
            <div class="collapse" v-if="collapsible"></div>
        </div>
        <div class="card-body" v-if="!collapsed">
		  <slot></slot>
        </div>
	</div>
</template>

<style scoped>
.card {
    background: var(--card_background);
    border: var(--card_border);
    border-radius: var(--card_border-radius);
    box-shadow: var(--card_shadow);
    padding: var(--card_padding);
    backdrop-filter: var(--card_backdrop-filter);
    width: 100%;
    position: relative;
}

.card-elevated {
    box-shadow: 0 4px 8px rgba(0,12,43,.05);
}

.card-head {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.has-head .card-body {
    padding-top: 1rem;
}

.collapse {
    width: 32px;
    height: 32px;
    border-radius: 999px;
    background: #AFAFAF80;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 14px;
}
</style>

<script setup>
    import { ref } from "vue"

    const collapsed = ref(false)

    const props = defineProps({
        title: String,
        collapsible: Boolean,
        expanded: Boolean
    })

    const toggle = () => {
        if(props.collapsible) collapsed.value = !collapsed.value
    }

    if (!props.expanded && props.collapsible) collapsed.value = true
</script>
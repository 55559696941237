import { createApp } from 'vue';
import App from './App.vue';
import i18n from './services/i18n';
import socket from "@lib/services/socket";
import './registerServiceWorker';
import router from './router';
import store from './store';
import vClickOutside from "click-outside-vue3";
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import 'primevue/resources/themes/lara-light-green/theme.css'
import "./assets/style.css";
import "./assets/theme.css";

import {
	UiAlert,
	UiAvatar,
	UiBack,
	UiBadge,
	UiButton,
	UiCard,
	UiCol,
	UiCopyToClipboard,
	UiGridTile,
	UiHeadline,
	UiDropdown,
	UiLabel,
	UiList,
	UiListItem,
	UiLoadingSpinner,
	UiLocked,
	UiModal,
	UiNavigation,
	UiProgressbar,
	UiRow,
	UiSelectGroup,
	UiSidebar,
	UiSkeleton,
	UiSpinner,
	UiSyncSpinner,
	UiThumbnail,
	UiThumbnails,
	UiToggleSwitch,
	UiUploadFile,
	UiVideo,
} from "@lib/components/UI"
import {
	Form,
	TextRender,
	LayoutMain
} from "@lib/components"
import {
	Icon
} from "@lib/components/icons"

const app = createApp(App);

app.component("UiAlert", UiAlert);
app.component("UiAvatar", UiAvatar);
app.component("UiBack", UiBack);
app.component("UiBadge", UiBadge);
app.component("UiButton", UiButton);
app.component("UiCard", UiCard);
app.component("UiCol", UiCol);
app.component("UiCopyToClipboard", UiCopyToClipboard);
app.component("UiGridTile", UiGridTile);
app.component("UiHeadline", UiHeadline);
app.component("UiDropdown", UiDropdown);
app.component("UiLabel", UiLabel);
app.component("UiList", UiList);
app.component("UiListItem", UiListItem);
app.component("UiLoadingSpinner", UiLoadingSpinner);
app.component("UiLocked", UiLocked);
app.component("UiModal", UiModal);
app.component("UiNavigation", UiNavigation);
app.component("UiProgressbar", UiProgressbar);
app.component("UiRow", UiRow);
app.component("UiSelectGroup", UiSelectGroup);
app.component("UiSidebar", UiSidebar);
app.component("UiSkeleton", UiSkeleton);
app.component("UiSpinner", UiSpinner);
app.component("UiSyncSpinner", UiSyncSpinner);
app.component("UiThumbnail", UiThumbnail);
app.component("UiThumbnails", UiThumbnails);
app.component("UiToggleSwitch", UiToggleSwitch);
app.component("UiUploadFile", UiUploadFile);
app.component("UiVideo", UiVideo);
app.component("TextRender", TextRender);
app.component("Form", Form);
app.component("LayoutMain", LayoutMain);
app.component("Icon", Icon);

app.config.globalProperties.$t = i18n.global.t;

/* Socket */
let socket_connected = false;
let reconnecting = false;

const listenChatMessageSocket = () => {
  if (socket.connected && socket_connected !== true) {
    socket_connected = true;
    reconnecting = false;

    if (store.state.user.user) {
      socket.off("chat-message-list");

      socket.on("chat-message-list", (data) => {
        // store.dispatch("chat/newMessage", data).then(() => {});
      });
    }
    return;
  }
  if (!socket.connected) {
    socket_connected = false;
    reconnecting = true;
    socket.off("chat-message");
  }
};

setInterval(() => {
  listenChatMessageSocket();
}, 1000);
/* Socket End */

app.use(vClickOutside);
app.use(store);
app.use(router);
app.use(i18n);
app.use(PrimeVue);
app.use(ToastService);
app.mount('#app');

<template>
	<div :class="classes">
		<div class="icon" v-if="icon">
			<Icon :icon="icon" size="24"></Icon>
		</div>
		<slot></slot>
	</div>
</template>

<script setup>
	import { computed } from 'vue'
	import { Icon } from '../icons'

	const props = defineProps({
		size: {
			type: String,
			default: 'l'
		},
		icon: String,
		bold: Boolean,
		align: {
			type: String,
			default: 'left'
		}
	})

	const classes = [
		'ui-headline',
		`ui-headline-${props.size}`,
		`align-${props.align}`,
		props.bold && 'bold',
	]
</script>

<style scoped>
	.ui-headline {
		color: #000;
		display: flex;
		gap: 8px;
		align-items: center;
	}

	.ui-headline-l {
		font-size: var(--headline-l-font-size);
		font-weight: 600;
		line-height: normal;
		letter-spacing: -0.52px;
	}

	.ui-headline-m {
	    font-size: var(--headline-m-font-size);
	    font-style: normal;
	    font-weight: 600;
	    line-height: normal;
	    letter-spacing: -0.52px;
	}

	.ui-headline-s {
	    font-size: 19px;
	    font-style: normal;
	    font-weight: 600;
	    line-height: normal;
	}

	.darkmode .ui-headline-l {
	  color: #62666A;
	}

	.darkmode .ui-headline-m {
	  color: #fff;
	}

	.darkmode .ui-headline-s {
	  color: #969C9D;
	}

	.ui-headline .icon {
		height: var(--headline-icon_size);
	    width: var(--headline-icon_size);
	    background: var(--headline-icon_background);
	    border: var(--headline-icon_border);
	    border-radius: var(--headline-icon_border-radius);
	    box-shadow: var(--headline-icon_shadow);
	    display: flex;
	    justify-content: center;
    	align-items: center;
	}

	.ui-headline.bold {
		font-weight: 600;
	}

	.ui-headline.align-center {
		text-align: center;
		justify-content: center;
	}
</style>
<template>
	<div :class="classes">
		<component :is="element" :to="route" :href="href" style="display: flex;align-items: center;font-size: 19px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -0.38px;gap: 8px;">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
				<path d="M2.25 9.83331H13.0833C17.8698 9.83331 21.75 13.7135 21.75 18.5V20.6666M2.25 9.83331L8.75 16.3333M2.25 9.83331L8.75 3.33331" :stroke="$store.state.dashboard.config.theme_mode === 'darkmode' ? '#fff' : '#000'" stroke-width="2.67" stroke-linecap="round" stroke-linejoin="round"/>
			</svg>
			<div v-if="text">{{ text }}</div>
		</component>
	</div>
</template>

<script setup>
	import { computed } from "vue"

	const props = defineProps({
		route: String,
		href: String,
		text: String,
		fixed: Boolean,
		as: {
	      type: String,
	      default: 'div'
	    }
	})

	const classes = computed(() => [
		'ui-back',
		props.fixed && 'fixed',
	])

	const element = computed(() => {
		if (props.route) {
	        return "router-link";
	     }
	    if (props.href) {
	        return "a";
	     }
	      
	      return props.as;
	})
</script>

<style scoped>
	.ui-back.fixed {
	    box-shadow: var(--user-navigation_shadow);
	    border-top: var(--user-navigation_border-bottom);
	    position: var(--user-navigation_position);
	    bottom: 0;
	    left: 0;
	    z-index: 16;
	    background: #fff;
	    width: 100%;
	    height: 73px;
	    display: flex;
	    align-items: center;
	    justify-content: center;
	}
</style>
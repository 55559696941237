<template>
	<div :class="classes">
		<slot></slot>
	</div>
</template>

<script setup>
	import { computed } from "vue"

	const props = defineProps({
		color: {
			type: String,
			default: 'primary'
		},
	})

	const classes = computed(() => [
		'ui-alert',
		`ui-alert-${props.color}`
	])
</script>

<style scoped>
	.ui-alert {
		background: black;
	    padding: 0.75rem;
	    border-radius: 16px;
	}
	.ui-alert-warning {
		background: #fef3c7;
		border: 1px solid #f1e7bf;
	}
</style>
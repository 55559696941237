import OverviewView from './OverviewView.vue';
import PlanView from './PlanView.vue';
import EducationView from './EducationView.vue';
import EducationIndexView from './EducationIndexView.vue';
import EducationCoursesView from './EducationCoursesView.vue';
import EducationCourseView from './EducationCourseView.vue';
import MembersView from './MembersView.vue';
import SettingsView from './SettingsView.vue';
import SettingsIndexView from './SettingsIndexView.vue';
import SettingsProfileView from './SettingsProfileView.vue';
import SettingsSubscriptionsView from './SettingsSubscriptionsView.vue';
import SettingsSubscriptionView from './SettingsSubscriptionView.vue';
import SettingsConfigurationView from './SettingsConfigurationView.vue';
import SettingsAffiliateView from './SettingsAffiliateView.vue';
import SettingsImportAccountView from './SettingsImportAccountView.vue';
import SettingsPointsView from './SettingsPointsView.vue';
import PartnerView from './PartnerView.vue';
import PartnerIndexView from './PartnerIndexView.vue';
import PartnerTradingDeskView from './PartnerTradingDeskView.vue';
import PartnerProductsView from './PartnerProductsView.vue';
import PartnerPlansView from './PartnerPlansView.vue';
import BonusView from './BonusView.vue';
import BonusIndexView from './BonusIndexView.vue';
import UpdatesView from './UpdatesView.vue';
import ProfileView from './ProfileView.vue';
import OnboardingView from './OnboardingView.vue';
import OnboardingQuestionsView from './OnboardingQuestionsView.vue';
import TradingView from './TradingView.vue';
import TradingDaysView from './TradingDaysView.vue';
import TradingDayFullView from './TradingDayFullView.vue';
import TradingPlatformView from './TradingPlatformView.vue';
import TradingAccountsView from './TradingAccountsView.vue';
import TradingAccountView from './TradingAccountView.vue';
import TradingAccountIndexView from './TradingAccountIndexView.vue';
import TradingAccountTradesView from './TradingAccountTradesView.vue';
import TradingAccountRiskView from './TradingAccountRiskView.vue';
import TradingAccountSettingsView from './TradingAccountSettingsView.vue';
import TradingAccountImportTradesView from './TradingAccountImportTradesView.vue';
import TradingAccountFundedOnboardingView from './TradingAccountFundedOnboardingView.vue';
import TradingAccountFundedOnboardingContractView from './TradingAccountFundedOnboardingContractView.vue';
import TradingAccountLiveFundedOnboardingView from './TradingAccountLiveFundedOnboardingView.vue';
import TradingAccountCertificateFormView from './TradingAccountCertificateFormView.vue';
import TradingMarketDataView from './TradingMarketDataView.vue';
import TradingPlayBooksView from './TradingPlayBooksView.vue';
import TradingPlayBookView from './TradingPlayBookView.vue';
import ListView from './ListView.vue';
import ListItemView from './ListItemView.vue';
import TradingCalendarView from './TradingCalendarView.vue';
import TradingReportsView from './TradingReportsView.vue';
import BalanceView from './BalanceView.vue';
import ReportView from './ReportView.vue';
import CreateReportView from './CreateReportView.vue';
import ModuleView from './ModuleView.vue';
import TradingChecklistView from './TradingChecklistView.vue';
import TradingChecklistMindsetView from './TradingChecklistMindsetView.vue';
import CalendarView from './CalendarView.vue';
import KnowledgebaseView from './KnowledgebaseView.vue';
import CheckinView from './checkin/CheckinView.vue';
import CommunityView from './CommunityView.vue';
import InstantFundingView from './InstantFundingView.vue';

export default [
	{
	    path: '',
	    name: 'dashboard',
	    component: OverviewView,
	  },
	 {
	    path: 'plan',
	    name: 'dashboard_plan',
	    component: PlanView,
	  },
	  {
	    path: 'education',
	    component: EducationView,
	    children: [
	    	{
	    		path: '',
			    name: 'dashboard_education',
			    component: EducationIndexView,
	    	},
	    	{
	    		path: 'courses',
			    name: 'dashboard_education_courses',
			    component: EducationCoursesView,
	    	},
	    	{
	    		path: 'courses/:courseId',
			    name: 'dashboard_education_course',
			    component: EducationCourseView,
	    	},
	    ]
	  },
	  {
	    path: 'settings',
	    component: SettingsView,
	    children: [
	    	{
	    		path: '',
	    		name: 'dashboard_settings',
	    		component: SettingsIndexView,
	    	},
	    	{
	    		path: 'profile',
	    		name: 'settings_profile',
	    		component: SettingsProfileView,
	    	},
	    	{
	    		path: 'subscriptions',
	    		name: 'dashboard_settings_subscriptions',
	    		component: SettingsSubscriptionsView,
	    	},
	    	{
	    		path: 'subscriptions/:subscriptionId',
	    		name: 'dashboard_settings_subscription',
	    		component: SettingsSubscriptionView,
	    	},
	    	{
	    		path: 'configuration',
	    		name: 'dashboard_settings_configuration',
	    		component: SettingsConfigurationView,
	    	},
	    	{
	    		path: 'affiliate',
	    		name: 'dashboard_settings_affiliate',
	    		component: SettingsAffiliateView,
	    	},
	    	{
	    		path: 'import',
	    		name: 'dashboard_settings_import_account',
	    		component: SettingsImportAccountView,
	    	},
	    	{
	    		path: 'import/:accountId',
	    		name: 'dashboard_settings_import_account_trade_data',
	    		component: SettingsImportAccountView,
	    	},
	    	{
	    		path: 'points',
	    		name: 'dashboard_settings_points',
	    		component: SettingsPointsView,
	    	},
	    ]
	  },
	  {
	    path: 'partner',
	    component: PartnerView,
	    children: [
		    {
		    	path: '',
			    name: 'partner',
			    component: PartnerIndexView,	
		    },
		    {
		    	path: 'desk',
			    name: 'partner_desk',
			    component: PartnerTradingDeskView,	
		    },
		    {
		    	path: 'products',
			    name: 'partner_products',
			    component: PartnerProductsView,	
		    },
		    {
		    	path: 'plans',
			    name: 'partner_plans',
			    component: PartnerPlansView,	
		    },
	    ]
	  },
	  {
	    path: 'members',
	    name: 'members',
	    component: MembersView,
	  },
	  {
	    path: 'bonus',
	    component: BonusView,
	    children: [
		    {
		    	path: '',
			    name: 'bonus',
			    component: BonusIndexView,	
		    }
	    ]
	  },
	  {
	    path: 'profile',
	    name: 'dashboard_profile',
	    component: ProfileView,
	  },
	  {
	    path: 'onboarding',
	    name: 'dashboard_onboarding',
	    component: OnboardingView,
	  },
	  {
	    path: 'updates',
	    name: 'dashboard_updates',
	    component: UpdatesView,
	  },
	  {
	    path: 'trading',
	    name: 'dashboard_trading',
	    component: TradingView,
	    children: [
	    		{
			    path: 'days',
			    name: 'dashboard_trading_days',
			    component: TradingDaysView
			  },
			  {
			    path: 'marketdata',
			    name: 'dashboard_trading_marketdata',
			    component: TradingMarketDataView,
			  },
			  {
			    path: 'accounts',
			    name: 'trading_accounts',
			    component: TradingAccountsView,
			  },
			  {
			    path: 'accounts/:accountId',
			    component: TradingAccountView,
			    children: [
			    	{
			    		path: '',
			    		name: 'dashboard_trading_index',
			    		component: TradingAccountIndexView,
			    	},
			    	{
			    		path: 'overview',
			    		name: 'dashboard_trading_account',
			    		component: TradingAccountIndexView,
			    	},
			    	{
				    path: 'days',
				    name: 'dashboard_trading_account_days',
				    component: TradingDaysView
				   },
				   {
			    		path: 'days/:tradingDayId',
					    name: 'dashboard_trading_account_day',
					    component: TradingDayFullView,
			       },
			       {
					    path: 'days/:date/:tradeId',
					    name: 'dashboard_trading_account_days_trade',
					    component: TradingAccountIndexView,
				   },
				   {
					    path: 'trades',
					    name: 'dashboard_trading_account_trades',
					    component: TradingAccountTradesView,
				   },
				   {
				    path: 'risk',
				    name: 'dashboard_trading_account_risk',
				    component: TradingAccountRiskView,
				   },
				   {
				    path: 'settings',
				    name: 'dashboard_trading_account_settings',
				    component: TradingAccountSettingsView,
				   },
				   {
				    path: 'import',
				    name: 'dashboard_trading_account_import_trades',
				    component: TradingAccountImportTradesView,
				   },
			    ]
			   },
			   {
			    path: 'accounts/:accountId/onboarding',
			    name: 'dashboard_trading_account_funded_onboarding',
			    component: TradingAccountFundedOnboardingView,
			   },
			   {
			    path: 'accounts/:accountId/onboarding/contract',
			    name: 'dashboard_trading_account_funded_onboarding_contract',
			    component: TradingAccountFundedOnboardingContractView,
			   },
			   {
			    path: 'accounts/:accountId/onboarding/trading-desk',
			    name: 'dashboard_trading_account_live_funded_onboarding_contract',
			    component: TradingAccountLiveFundedOnboardingView,
			   },
			   {
			    path: 'accounts/:accountId/certificate/form',
			    name: 'dashboard_trading_account_certificate_form',
			    component: TradingAccountCertificateFormView,
			   },
			   {
			    path: 'accounts/:accountId/playbooks',
			    name: 'dashboard_trading_playbooks',
			    component: TradingPlayBooksView,
			  },
			   {
			    path: 'accounts/:accountId/playbooks/:playbookId',
			    name: 'dashboard_trading_playbook',
			    component: TradingPlayBookView,
			  },
			   {
			    path: 'accounts/:accountId/calendar',
			    name: 'dashboard_trading_calendar',
			    component: TradingCalendarView,
			  },
			   {
			    path: 'accounts/:accountId/reports',
			    name: 'dashboard_trading_reports',
			    component: TradingReportsView,
			  },
			  {
			    path: 'accounts/:accountId/list/:listKey',
			    name: 'list',
			    component: ListView,
			  },
			   {
			    path: 'accounts/:accountId/list/:listKey/:itemId',
			    name: 'list_item',
			    component: ListItemView,
			  },
	    ]
	  },
	  {
	    path: 'platform',
	    name: 'dashboard_trading_platform',
	    component: TradingPlatformView,
	  },
	  {
	    path: 'balance',
	    name: 'dashboard_balance',
	    component: BalanceView,
	  },
	  {
	    path: 'trading/:accountId/:tradingDay/full',
	    name: 'dashboard_trading_day_full',
	    component: TradingDayFullView,
	  },
	  {
	    path: 'report',
	    name: 'dashboard_report',
	    component: ReportView,
	  },
	  {
	    path: 'report/new',
	    name: 'dashboard_create_report',
	    component: CreateReportView,
	  },
	  {
	    path: 'module/:moduleId',
	    name: 'dashboard_module',
	    component: ModuleView,
	  },
	  {
	    path: 'checklist',
	    name: 'trading_checklist',
	    component: TradingChecklistView,
	    children: [
	    	{
	    		path: 'mindset',
			    name: 'trading_checklist_mindset',
			    component: TradingChecklistMindsetView,
	    	}
	    ]
	  },
	  {
	    path: 'calendar',
	    name: 'calendar',
	    component: CalendarView,
	  },
	  {
	    path: 'knowledgebase',
	    name: 'knowledgebase',
	    component: KnowledgebaseView,
	  },
	  {
	    path: '/checkin',
	    name: 'checkin',
	    component: CheckinView,
	  },
	  {
	    path: '/community',
	    name: 'community',
	    component: CommunityView,
	  },
	  {
	    path: '/instant-funding',
	    name: 'instant_funding',
	    component: InstantFundingView,
	  },
]
import http from "../../services/http";
import cookie from "../../services/cookie";
import config from "../../config";

const AuthApi = {
  register(payload) {
    return http.post("/v1/users", payload.data);
  },
  emailRegister(payload) {
    return http.post("/v1/users/email", payload);
  },
  login(payload) {
    const { email, password } = payload;

    const user = {
      email,
      password,
    };

    return http.post("/users/login", { user });
  },
  authenticate() {
    return http.get("/user");
  },
  resetPassword(payload) {
    return http.post("/user/password/reset", payload);
  },
  setNewPassword(payload) {
    return http.put("/user/password/reset", payload);
  },
};

const auth = {
  namespaced: true,
  state: {
    authenticated: false,
    accessToken: cookie.get("access_token"),
    adminAccessToken: cookie.get("admin_access_token"),
  },
  getters: {
    authenticated: (state) => {
      return state.authenticated;
    },
  },
  mutations: {
    authenticate: (state, token, rootState) => {
      cookie.set("access_token", token);
      state.accessToken = token;
      http.defaults.headers.common.Authorization = `Bearer ${token}`;
      state.authenticated = true;
    },
    signOut: (state) => {
      cookie.remove("access_token");
      state.accessToken = null;
      state.authenticated = false;
    },
    clearSession: (state) => {
      cookie.remove("access_token");
      state.accessToken = null;
      state.authenticated = false;
    },
  },
  actions: {
    register: (state, payload) =>
      new Promise((resolve, reject) => {
        AuthApi.register(payload)
          .then((res) => {
            state.commit("authenticate", res.data.user.token);
            state.commit("user/updateUser", res.data.user, { root: true });
            state.commit("dashboard/updateVersion", res.data.user.version, { root: true });
            state.commit("dashboard/updateConfig", res.data.user.config, { root: true })
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    emailRegister: (state, payload) =>
      new Promise((resolve, reject) => {
        AuthApi.emailRegister(payload)
          .then((res) => {
            state.commit("authenticate", res.data.user.token);
            state.commit("user/updateUser", res.data.user, { root: true });
            state.commit("dashboard/updateVersion", res.data.user.version, { root: true });
            state.commit("dashboard/updateConfig", res.data.user.config, { root: true })
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    login: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        AuthApi.login(payload)
          .then((res) => {
            commit("authenticate", res.data.user.token);
            commit("user/updateUser", res.data.user, { root: true });
            commit("dashboard/updateVersion", res.data.user.version, { root: true });
            commit("dashboard/updateConfig", res.data.user.config, { root: true })
            
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    authenticate: ({ state, commit }) =>
      new Promise((resolve, reject) => {
        if (state.accessToken) {
          http.defaults.headers.common.Authorization = `Bearer ${state.accessToken}`;
          AuthApi.authenticate(state.accessToken)
            .then((res) => {
              console.log(res.data)
              try {
                commit("authenticate", res.data.user.token);
                commit("user/updateUser", res.data.user, { root: true });
                commit("dashboard/updateVersion", res.data.user.version, { root: true });
                commit("dashboard/updateConfig", res.data.user.config, { root: true })
              } catch(err) {
                console.log(err)
              }
              if (res.data.supportChatId) cookie.set("support_chat_id", res.data.supportChatId);
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          reject({ error: "no_token" });
        }
      }),
    signOut: ({ commit }) =>
      new Promise((resolve) => {
        commit("signOut");
        commit("user/clearUser", null, { root: true });
        resolve();
      }),
    clearSession: ({ commit }) =>
      new Promise(() => {
        commit("user/clearUser", null, { root: true });
        commit("clearSession");
      }),
    authenticateByToken: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        if (payload.token) {
          commit("authenticate", payload.token);
          commit("user/updateUser", payload.user, { root: true });
          commit("dashboard/updateVersion", payload.user.version, { root: true });
          commit("dashboard/updateConfig", payload.user.config, { root: true })
          resolve();
        } else {
          reject({ error: "no_token" });
        }
      }),
    resetPassword: ({ state, commit }, payload) =>
      new Promise((resolve, reject) => {
        AuthApi.resetPassword(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
    setNewPassword: ({ state, commit }, payload) =>
      new Promise((resolve, reject) => {
        AuthApi.setNewPassword(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
  },
};

export default auth;
<template>
  <template v-if="brokerUser">
    <UiRow gap="2">
      <div>
        <div class="mb-1">{{ $t('view.trading_platform.credentials.username') }}:</div>
        <strong>
          <UiGridTile>
            <UiCopyToClipboard :text-to-copy="brokerUser.username">
              <UiRow align="center" gap="2">
                {{ brokerUser.username }}
              </UiRow>
            </UiCopyToClipboard>
          </UiGridTile>
        </strong>
      </div>
      <div>
        <div class="mb-1">{{ $t('view.trading_platform.credentials.password') }}:</div>
        <strong>
          <UiGridTile>
            <UiCopyToClipboard :text-to-copy="brokerUser.password">
              <UiRow align="center" gap="2">
                {{ brokerUser.password }}
              </UiRow>
            </UiCopyToClipboard>
          </UiGridTile>
        </strong>
      </div>
    </UiRow>
    <div class="mt-2">
      <label>
        <small>
          Paste the credentials into the respective fields by using "CTRL + V" on Windows or "COMMAND + V" on Mac.
        </small>
      </label>
    </div>
  </template>
  <UiSkeleton style="width: 100%;border-radius: 16px;" height="94px" v-else-if="loading"></UiSkeleton>
  <template v-else>
    <UiGridTile>
      {{ $t('view.trading_platform.credentials.no_credentials') }}
    </UiGridTile>
    <UiButton class="mt-2" href="https://purdia.com/evaluation">Start Evaluation</UiButton>
  </template>
</template>
<script setup>
import { ref, computed } from "vue"
import { useStore } from "vuex"

const loading = ref(true)
const store = useStore()

store.dispatch('dashboard/getBrokerUser').then(() => {
  loading.value = false
}).catch(() => {
  loading.value = false
})

const brokerUser = computed(() => store.state.dashboard.brokerUser)

</script>

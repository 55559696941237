<template>
		<!-- Back:Mobile -->
	<div class="mobile">
		<UiBack route="/" text="Back to Dashboard" fixed></UiBack>
	</div>

	<div class="container">
	<UiCard>
		<!-- Headline -->
		<UiHeadline class="mb-8" size="m">{{ $t('view.trading_platform.title') }}</UiHeadline>

	<!-- Credentials -->
	<TradingPlatformCredentials/>

	<!-- Platforms -->
	<div class="desktop">
		<UiRow gap="4" style="width: 100%;margin-top: 71px;">
      <UiGridTile full v-for="platform in platforms">
        <div style="display: flex;align-items: center;gap: 8px;">
          <div style="width: 48px;height: 48px;border-radius: 10px;background: #fff;display: flex;justify-content: center;align-items: center;font-size: 19px;font-weight: 500;">
          	{{ platform.icon }}
          </div>
          <div style="font-size: 19px;font-weight: 500;line-height: normal;letter-spacing: -1.14px;">{{ platform.name }}</div>
        </div>
        <br>
        <p>
        	<a :href="platform.url.link" target="_blank" v-if="platform.url">{{ platform.url.text }}</a>
        </p>
      </UiGridTile>
    </UiRow>
  	</div>
    <!-- Mobile -->
    <div class="mobile">
	    <UiCol gap="4" style="width: 100%;margin-top: 71px;">
	      <UiGridTile full v-for="platform in platforms">
	        <div style="display: flex;align-items: center;gap: 8px;">
	          <div style="width: 48px;height: 48px;border-radius: 10px;background: #fff;display: flex;justify-content: center;align-items: center;font-size: 19px;font-weight: 500;">
	          	{{ platform.icon }}
	          </div>
	          <div style="font-size: 19px;font-weight: 500;line-height: normal;letter-spacing: -1.14px;">{{ platform.name }}</div>
	        </div>
	        <br>
	        <p>
	        	<a :href="platform.url.link" target="_blank" v-if="platform.url">{{ platform.url.text }}</a>
	        </p>
	      </UiGridTile>
	    </UiCol>
  	</div>
  </UiCard>
  <UiAlert class="mt-8" color="warning">{{ $t('view.trading_platform.login_info') }}</UiAlert>
</div>
</template>

<script setup>
	import { ref, getCurrentInstance } from "vue"
	import TradingPlatformCredentials from "@/components/TradingPlatformCredentials"

	const instance = getCurrentInstance();
  const t = instance.appContext.config.globalProperties.$t;

	const platforms = ref([
		{
			name: t('view.trading_platform.ninjatrader.title'),
			icon: "NT",
			url: {
				text: t('view.trading_platform.ninjatrader.text'),
				link: "/page"
			}
		},
		{
			name: t('view.trading_platform.tradovate.title'),
			icon: "T",
			url: {
				text: t('view.trading_platform.tradovate.text'),
				link: "https://trader.tradovate.com"
			}
		},
		{
			name: t('view.trading_platform.tradingview.title'),
			icon: "TV",
			url: {
				text: t('view.trading_platform.tradingview.text'),
				link: "https://www.youtube.com/watch?v=0VO68XHjhbo"
			}
		}
	])
</script>
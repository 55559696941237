<template>
  <div class="user-nav nav">
        <UiHeadline size="s">{{ title }}</UiHeadline>

        <!-- Menu -->
        <ul>
          <li v-for="item in items"><router-link :to="item.route">{{ item.name }}<UiBadge type="danger" v-if="item.notification">{{ item.notification }}</UiBadge></router-link></li>
        </ul>
    </div>
</template>

<script setup>
  import { ref, getCurrentInstance } from "vue"

  const instance = getCurrentInstance();
  const t = instance.appContext.config.globalProperties.$t;

  const props = defineProps({
    title: String,
    items: Array
  })

  const accountDropdownItems = ref([
    {
      name: t('navigation.my_account.profile'),
      route: "/profile"
    },
    {
      name: t('navigation.my_account.bonus'),
      route: "/bonus"
    },
    {
      name: t('navigation.my_account.settings'),
      route: "/settings"
    },
    {
      name: t('navigation.my_account.sign_out'),
      route: "/signout"
    }
  ])
</script>

<style scoped>
  .user-nav {
    box-shadow: var(--user-navigation_shadow);
    border-bottom: var(--user-navigation_border-bottom);
    position: relative;
    top: 0;
    z-index: 16;
    background: #fff;
  }

  .nav {
    z-index: 16;
    width: 100%;
    max-width: var(--user-navigation_width);
    margin: auto;
    padding: var(--user-navigation_padding);
    padding-top: var(--user-navigation_padding-top);
  }

  .nav ul {
    color: #000;
    font-size: var(--user-navigation_font-size);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.38px;
    gap: 24px;
  }

  .nav ul li {
    display: flex;
    align-items: center;
  }

  .nav ul a {
    display: flex;
    align-items: center;
    gap: 4px;
    color: var(--nav-item_color);
    border-radius: var(--nav-item_border-radius);
    padding: var(--nav-item_padding);
  }

  .nav ul li a.router-link-active {
    border-bottom: var(--nav-item-active_border-bottom);
    color: var(--nav-item-active_color);
    background: var(--nav-item-active_background);
  }
</style>
<template>
	<div class="ui-dropdown" v-click-outside="onClickOutside">
		<div class="dropdown" @click="show = !show">
			<slot></slot>
		</div>
		<ul v-if="show">
			<li v-for="item in items"><router-link :to="item.route" @click="show = false">{{ item.name }}</router-link></li>
		</ul>
	</div>
</template>

<script setup>
	import { ref } from "vue"

	const props = defineProps({
		items: Array
	})

	const show = ref(false)

	const onClickOutside = () => {
		show.value = false
	}
</script>

<style scoped>
	.ui-dropdown {
		position: relative;
	}

	.ui-dropdown .dropdown {
		display: flex;
		align-items: center;
		cursor: pointer;
		border-radius: 12px;
		padding: 12px;
		gap: 15px;
	}

	.ui-dropdown .dropdown:hover {
		border-bottom: var(--nav-item-active_border-bottom);
	    color: var(--nav-item-active_color);
	    background: var(--nav-item-active_background);
	}

	.ui-dropdown ul {
		position: absolute;
		display: block;
	    z-index: 16;
	    background: #f3f4f6;
	    font-size: var(--dropdown_font-size);
	    font-weight: 500;
	    line-height: normal;
	    letter-spacing: -0.38px;
	    padding: 6px 12px;
	    border-radius: 12px;
	    right: 0;
	}

	.ui-dropdown ul li a {
	    padding: 12px;
	    display: inline-block;
	    width: 100%;
	    color: #000;
	}

	.ui-dropdown ul li a:hover {
	    color: var(--color-brand);
	}
</style>
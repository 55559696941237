<template>
	<!-- Headline -->
	<UiHeadline style="margin-bottom: 42px;">Balance</UiHeadline>

	<UiBack route="/" text="Back"></UiBack>

	<template v-if="!payoutStatus.payoutRequest">
		<div style="background: #F6F7F7;border-radius: 16px; padding: 10px;margin-bottom: 8px;display: block;">
		    <div style="color: #000;font-size: 24px;font-style: normal;font-weight: 500;line-height: normal;">
		      Available Payout Amount
		    </div>
		    <div style="display: flex;gap: 9px;justify-content: space-between;">
		      <div style="color: #000;font-size: 48px;font-style: normal;font-weight: 700;line-height: normal;letter-spacing: -2.88px;">
		        ${{ balance.balance }}
		      </div>
		    </div>
		  </div>

		  <!-- Form -->
		  <div style="max-width: 512px;margin-top: 42px;">
		  	<div class="form-row">
				  <div class="form-field">
				      <label>Withdrawal amount (USD)</label>
				      <input
				        type="number"
				        placeholder="0.00"
				        v-model="withdrawalAmount"
				      />
				      <p v-if="error" style="color: #FF2C46;margin-top: 4px">{{ error }}</p>
				  </div>
			  </div>
		  </div>

		  <!-- Stats -->
		    <div style="margin-top: 59px;display:grid;grid-template-columns: 1fr 1fr 1fr 1fr;gap: 19px;">
		      <div v-for="item in summary">
		        <!-- Label -->
		        <div style="color: #000;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -0.32px;margin-bottom: 18px;">
		          {{ item.title }}
		        </div>

		        <!-- Value -->
		        <div style="color: #000;font-size: 28px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -1.68px;">{{ item.value }}</div>

		        <!-- Info -->
		        <div style="color: #000;font-size: 17px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: 0.17px;margin-top: 3px;" v-if="item.info" v-html="item.info">
		        </div>
		      </div>
		    </div>

		<UiButton style="margin-top: 71px;" @click="submit">Submit Request</UiButton>
	</template>
	<template v-else>
		<div style="margin-top: 59px;display:grid;grid-template-columns: 1fr 1fr 1fr 1fr;gap: 19px;">
		      <div>
		        <!-- Label -->
		        <div style="color: #000;font-size: 16px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -0.32px;margin-bottom: 18px;">
		          You have a pending payout request
		        </div>

		        <!-- Value -->
		        <div style="color: #000;font-size: 28px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: -1.68px;">
		        	${{ payoutStatus.payoutRequest.amount }}
		        </div>

		        <!-- Info -->
		        <div style="color: #000;font-size: 17px;font-style: normal;font-weight: 500;line-height: normal;letter-spacing: 0.17px;margin-top: 3px;">
		        	In Progess
		        </div>
		      </div>
		    </div>
	</template>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"

	const store = useStore()
	const withdrawalAmount = ref(0)
	const minPayout = ref(100)

	store.dispatch("dashboard/getBalance")
	store.dispatch("dashboard/getPayoutStatus")

	const balance = computed(() => store.state.dashboard.tradingBalance)
	const payoutStatus = computed(() => store.state.dashboard.payoutStatus)

	const balanceAfterTransaction = computed(() => {
		if (withdrawalAmount.value < minPayout.value) return 0	
		if (balance.value.balance < withdrawalAmount.value) return 0

		return balance.value.balance - withdrawalAmount.value
	})

	const payoutAmount = computed(() => {
		if (withdrawalAmount.value < minPayout.value) return 0
		if (balance.value.balance < withdrawalAmount.value) return 0

		return withdrawalAmount.value
	})

	const error = computed(() => {
		if (withdrawalAmount.value == 0) return null
		if (withdrawalAmount.value < minPayout.value) return 'The minimum withdrawal amount is $100'
		if (balance.value.balance < withdrawalAmount.value) return 'Your balance is lower than the withdrawal'
	})

	const summary = computed(() => [
		{
			title: 'Payout amount',
			value: `$${payoutAmount.value * .7}`,
			info: '(Your 70% Profit Split)'
		},
		{
			title: 'Balance after transaction',
			value: `$${balanceAfterTransaction.value}`,
			info: ''
		},
	])

	const submit = () => {
		store.dispatch("dashboard/requestPayout", { 
			amount: withdrawalAmount.value,
			profitSplit: 70
		}).then(() => {})
	}
</script>
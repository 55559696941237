<template>
  <LayoutUser>
    <div class="login-view container">
        <div class="form card grid gap-1-5">
          <UiHeadline size="m">Login</UiHeadline>

          <Form :schema="formSchema" v-model="model" @submit="submit" :errors="errors" :submitting="submitting"></Form>
          <div class="text-center">Are you new? <router-link class="strong" to="/sign-up">Sign Up</router-link></div>
          <div class="text-center mt-8"><router-link to="/password/forgot"><strong>Forgot Password</strong></router-link></div>
        </div>
    </div>
  </LayoutUser>
</template>

<script setup>
  import { ref } from "vue"
  import { useStore } from "vuex"
  import { useRouter } from "vue-router"
  import S2 from "@/components/S2"
  import { LayoutUser } from "@lib/components"

  const store = useStore()
  const router = useRouter()
  const model = ref({
    email: "",
    password: ""
  })
  const submitting = ref(false)
  const errors = ref(null)

  const submit = () => {
    if (submitting.value) return
    store.dispatch("dashboard/updateInteracted", true)
    submitting.value = true;
    store.dispatch("auth/login", model.value)
    .then((res) => {
      submitting.value = false;
      if (res.data.user.plans.includes('pro')) {
        router.push('/desk?intro=play')
      } else {
        router.push('/')
      }
    })
    .catch((err) => {
      errors.value = err.response.data.errors;
      submitting.value = false;
    });
  }

  const formSchema = ref(`{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "key": "email",
              "type": "email",
              "label": "E-Mail address",
              "name": "email",
              "placeholder": "",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "password",
              "type": "password",
              "label": "Password",
              "name": "password",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "submit",
              "label": "Login"
            }
          ]
        }
      ]
    }
  ]
}
`);
</script>

<style scoped>
  .login-view {
    min-height: calc(100vh - var(--user-layout_padding-top));
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-view .form {
    width: 100%;
    max-width: 500px;
    margin: auto;
  }

  .login-view .submit {
    margin-top: 24px;
  }
</style>
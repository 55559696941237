import http from "@lib/services/http";
import cookie from "@lib/services/cookie";
import config from "@lib/store/modules/config";
import { isServiceHours } from "@lib/utils/utils";

const MARKET_DATA_PLANS = ["CME_TOP", "CBOT_TOP", "COMEX_TOP", "NYMEX_TOP"]

const DashboardApi = {
  // Dashboard
  get() {
    return http.get("/v1/uni/dashboard");
  },
  // Trading Account
  getTradingAccounts() {
    return http.get(`/v1/uni/accounts`);
  },
  syncTradingAccounts() {
    return http.get(`/v1/uni/accounts/sync`);
  },
  getTradingAccount(accountId) {
    return http.get(`/v1/uni/accounts/${accountId}`);
  },
  getTradingAccountPerformance(accountId) {
    return http.get(`/v1/uni/accounts/${accountId}/performance`);
  },
  getTradingAccountRiskParameters(accountId) {
    return http.get(`/v1/uni/accounts/${accountId}/risk-parameters`);
  },
  syncTradingAccount(accountId) {
    return http.get(`/v1/uni/accounts/${accountId}/sync`);
  },
  getTradingAccountChart(accountId) {
    return http.get(`/v1/uni/accounts/${accountId}/chart`);
  },
  getTradingDays(accountId) {
    return http.get(`/v1/uni/accounts/${accountId}/trading-days`);
  },
  syncTradingAccountTradingDays(accountId) {
    return http.get(`/v1/uni/accounts/${accountId}/trading-days/sync`);
  },
  resetTradingAccount(payload) {
    return http.post(`/v1/uni/accounts/${payload.accountId}/reset`, payload.data);
  },
  getTradingDay(payload) {
    return http.get(`/v1/uni/accounts/${payload.accountId}/trading-days/${payload.tradingDayId}`);
  },
  updateTradingDayReport(payload) {
    return http.post(`/v1/uni/accounts/${payload.accountId}/trading-days/${payload.tradingDayId}/report`, payload.data);
  },
  updateTradingDayTrade(payload) {
    return http.post(`/v1/uni/accounts/${payload.accountId}/trading-days/${payload.date}/trade/${payload.tradeId}`, payload.data);
  },
  getTradingAccountTrades(payload) {
    return http.get(`/v1/uni/accounts/${payload.accountId}/trades`);
  },
  getTradingDayTrades(payload) {
    return http.get(`/v1/uni/accounts/${payload.accountId}/trades/${payload.date}`);
  },
  updateTrade(payload) {
    return http.post(`/v1/uni/accounts/${payload.accountId}/trades/${payload.tradeId}`, payload.data);
  },
  confirmFundedRules(payload) {
    return http.post(`/v1/uni/accounts/${payload.accountId}/funded/rules`);
  },
  signTradingAccountContract(payload) {
    return http.post(`/v1/uni/accounts/${payload.accountId}/contract`, payload.data);
  },
  startSecondStep(payload) {
    return http.post(`/v1/uni/accounts/${payload.accountId}/step-2`);
  },
  addTradingAccount() {
    return http.post(`/v1/uni/accounts`);
  },
  createPlayBook(payload) {
    return http.post(`/v1/uni/accounts/${payload.accountId}/playbook`);
  },
  getPlaybooks(payload) {
    return http.get(`/v1/uni/accounts/${payload.accountId}/playbooks`);
  },
  getPlaybook(payload) {
    return http.get(`/v1/uni/accounts/${payload.accountId}/playbook/${payload.playbookId}`);
  },
  updatePlayBook(payload) {
    return http.post(`/v1/uni/accounts/${payload.accountId}/playbook/${payload.playbookId}/update`, payload.data);
  },
  uploadTradeDataToTradingAccount(payload) {
    return http.post(`/v1/uni/accounts/${payload.accountId}/trades?timezone=${payload.timezone}`, payload.files);
  },
  // Market Data
  getMarketData() {
    return http.get("/v1/uni/marketdata");
  },
  getMarketDataPlans() {
    return http.get("/v1/uni/marketdata/plans");
  },
  // Modules
  getModules() {
    return http.get(`/v1/uni/modules`);
  },
  getModule(payload) {
    return http.get(`/v1/uni/modules/${payload}`);
  },
  updateModule(payload) {
    return http.post(`/v1/uni/modules/${payload.moduleId}/update`, payload.data);
  },
  createModuleEntry(payload) {
    return http.post(`/v1/uni/modules/${payload.moduleId}`, payload.data);
  },
  // Balance/Payout
  getTradingBalance() {
    return http.get("/v1/uni/trading-balance");
  },
  requestPayout(payload) {
    return http.post("/v1/uni/trading-balance/payout", payload);
  },
  getPayoutStatus() {
    return http.get("/v1/uni/trading-balance/payout");
  },
  // Subscriptions
  getSubscriptions() {
    return http.get("/v1/uni/subscriptions");
  },
  cancelSubscription(payload) {
    return http.post(`/v1/uni/subscriptions/${payload.subscriptionId}/cancel`);
  },
  // Business/Partner
  getBusiness() {
    return http.get("/v1/business");
  },
  getPartnerDesk() {
    return http.get("/v1/uni/partner/desk");
  },
  becomeAffiliate() {
    return http.post("/v1/uni/affiliate/join");
  },
  // Others
  getLeaderboard() {
    return http.get("/v1/pit/leaderboard");
  },
  listDesks() {
    return http.get("/v1/uni/desks");
  },
  getDesk() {
    return http.get(`/v1/uni/desk`);
  },
  updateUserModule(payload) {
    return http.post(`/v1/uni/user/modules`, payload);
  },
  getOpenTickets() {
    return http.get(`/v1/uni/tickets/open`);
  },
  createItem(payload) {
    return http.post(`/v1/uni/items/${payload.listKey}`, payload.data);
  },
  listItems(payload) {
    return http.get(`/v1/uni/items/${payload.listKey}`, {
      params: payload.params
    });
  },
  getItem(payload) {
    return http.get(`/v1/uni/items/${payload.listKey}/${payload.itemId}`, {
      params: payload.params,
    });
  },
  updateItem(payload) {
    return http.post(`/v1/uni/items/${payload.listKey}/${payload.itemId}/update`, payload.data, {
      params: payload.params,
    });
  },
  getCertificates() {
    return http.get("/v1/uni/certificates");
  },
  getEvaluations() {
    return http.get("/v1/evaluations");
  },
  checkout(payload) {
    return http.post("/v1/trading/checkout", payload.data);
  },
  checkDiscountCode(payload) {
    return http.post("/v1/trading/checkout/discount", payload.data);
  },
  getBalance() {
    return http.get("/v1/uni/balance");
  },
  getBalanceTransactions() {
    return http.get("/v1/uni/balance/transactions");
  },
  confirmOnboardingStep1(payload) {
    return http.post("/v1/uni/onboarding/1", payload.data);
  },
  confirmCredit(payload) {
    return http.post("/v1/uni/credit/confirm", payload.data);
  },
  getBrokerUser() {
    return http.get("/v1/uni/broker/credentials");
  },
  getCheckout() {
    return http.get("/v1/uni/shop/checkout");
  },
};

const defaultConfig = {
  language: cookie.get("lang") ? cookie.get("lang") : 'en',
  navigation_clock_show: true,
  navigation_clock_timezones: [],
  theme_mode: 'light',
  pro_navigation_enabled: false
}

const dashboard = {
  namespaced: true,
  state: {
    accounts: [],
    currentAccount: null,
    currentTradingDay: null,
    tradingDays: [],
    modules: [],
    module: null,
    tradingBalance: {
      balance: 0
    },
    payoutStatus: {},
    subscriptions: [],
    currentSubscription: null,
    business: null,
    leaderboard: {},
    marketData: [],
    marketDataPlans: [],
    trades: [],
    currentTrade: null,
    version: null,
    config: {...defaultConfig},
    desks: [],
    currentDesk: null,
    isServiceHours: isServiceHours(),
    partnerDesk: null,
    tickets: [],
    interacted: false,
    showJournalSidebar: false,
    playbooks: [],
    certificates: [],
    currentCertificate: null,
    balance: null,
    updateBrokerUser: null,
    checkoutSession: null
  },
  getters: {
    account: (state) => state.accounts.find(a => a.id === state.currentAccount),
    subscription: (state) => state.subscriptions.find(s => s.id === state.currentSubscription),
    availableMarketDataPlans: (state) => state.marketDataPlans.filter(p => MARKET_DATA_PLANS.includes(p.name)),
    trade: (state) => state.trades.find(t => t.id === state.currentTrade),
    tradingDay: (state) => state.tradingDays.find(t => t.id === state.currentTradingDay),
    desk: (state) => state.desks.find(t => t.id === state.currentDesk),
    availablePlaybooks: (state) => state.playbooks.map(p => ({
      value: p.id,
      label: p.name
    })),
    certificate: (state) => state.certificates.find(a => a.id === state.currentCertificate),
  },
  mutations: {
    updateAccounts: (state, payload) => {
      state.accounts = payload.accounts
    },
    updateAccount: (state, payload) => {
      const index = state.accounts.findIndex(a => a.id == state.currentAccount)
      if (index !== -1) state.accounts[index] = payload
    },
    setAccount: (state, payload) => {
      state.currentAccount = payload
      state.tradingDays = []
      state.trades = []
    },
    setTradingDay: (state, payload) => {
      state.currentTradingDay = payload
    },
    updateAccountTradingDays: (state, payload) => {
      state.tradingDays = payload
    },
    updateAccountTradingDay: (state, payload) => {
      const index = state.tradingDays.findIndex(a => a.id == payload.id)
      if (index !== -1) {
        state.tradingDays[index] = payload
      } else {
        state.tradingDays.push(payload)
      }
    },
    updateModules: (state, payload) => {
      state.modules = payload
    },
    updateModule: (state, payload) => {
      state.module = payload
    },
    updateTradingBalance: (state, payload) => {
      state.tradingBalance = payload
    },
    updatePayoutStatus: (state, payload) => {
      state.payoutStatus = payload
    },
    updateSubscriptions: (state, payload) => {
      state.subscriptions = payload
    },
    setSubscription: (state, payload) => {
      state.currentSubscription = payload
    },
    updateBusiness: (state, payload) => {
      state.business = payload
    },
    updatePartnerDesk: (state, payload) => {
      state.partnerDesk = payload
    },
    updateLeaderboard: (state, payload) => {
      state.leaderboard = payload
    },
    updateMarketData: (state, payload) => {
      state.marketData = payload
    },
    updateMarketDataPlans: (state, payload) => {
      state.marketDataPlans = payload
    },
    setTradingDayTrade: (state, payload) => {
      state.currentTrade = payload
    },
    updateTradingDayTrades: (state, payload) => {
      state.trades = payload
    },
    updateVersion: (state, payload) => {
      state.version = payload;
    },
    updateConfig: (state, payload) => {
      state.config = payload ? {
        ...defaultConfig,
        ...payload
      } : defaultConfig
    },
    updateDesks: (state, payload) => {
      state.desks = payload
    },
    updateDesk: (state, payload) => {
      const index = state.desks.findIndex(a => a.id == payload.id)
      if (index !== -1) {
        state.desks[index] = payload
      } else {
        state.desks.push(payload)
      }
    },
    setDesk: (state, payload) => {
      state.currentDesk = payload
    },
    updateTickets: (state, payload) => {
      state.tickets = payload
    },
    updateInteracted: (state, payload) => {
      state.interacted = payload
    },
    updatePlaybooks: (state, payload) => {
      state.playbooks = payload
    },
    setCertificate: (state, payload) => {
      state.currentCertificate = payload
    },
    updateBalance: (state, payload) => {
      state.balance = payload
    },
    updateBrokerUser: (state, payload) => {
      state.brokerUser = payload
    },
    updateCheckoutSession: (state, payload) => {
      state.checkoutSession = payload
    },
  },
  actions: {
    get: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.get()
          .then((res) => {
            state.commit("updateAccounts", res.data);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getTradingAccounts: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getTradingAccounts()
          .then((res) => {
            state.commit("updateAccounts", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    syncTradingAccounts: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.syncTradingAccounts()
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getTradingAccount: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getTradingAccount(payload)
          .then((res) => {
            state.commit("updateAccount", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getTradingAccountPerformance: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getTradingAccountPerformance(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getTradingAccountRiskParameters: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getTradingAccountRiskParameters(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    syncTradingAccount: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.syncTradingAccount(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    setAccount: (state, payload) => state.commit("setAccount", payload),
    getTradingAccountChart: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getTradingAccountChart(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getTradingDays: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getTradingDays(payload)
          .then((res) => {
            state.commit("updateAccountTradingDays", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    syncTradingAccountTradingDays: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.syncTradingAccountTradingDays(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    resetTradingAccount: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.resetTradingAccount(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getTradingDay: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getTradingDay(payload)
          .then((res) => {
            state.commit("updateAccountTradingDay", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getTradingAccountTrades: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getTradingAccountTrades(payload)
          .then((res) => {
            state.commit("updateTradingDayTrades", res.data.trades)
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getTradingDayTrades: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getTradingDayTrades(payload)
          .then((res) => {
            state.commit("updateTradingDayTrades", res.data.trades)
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updateTrade: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.updateTrade(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    setTradingDayTrade: (state, payload) => state.commit("setTradingDayTrade", payload),
    setTradingDay: (state, payload) => state.commit("setTradingDay", payload),
    updateTradingDayReport: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.updateTradingDayReport(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updateTradingDayTrade: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.updateTradingDayTrade(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    confirmFundedRules: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.confirmFundedRules(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    signTradingAccountContract: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.signTradingAccountContract(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    startSecondStep: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.startSecondStep(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getMarketData: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getMarketData(payload)
          .then((res) => {
            state.commit("updateMarketData", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getMarketDataPlans: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getMarketDataPlans(payload)
          .then((res) => {
            state.commit("updateMarketDataPlans", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getModules: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getModules(payload)
          .then((res) => {
            state.commit("updateModules", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getModule: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getModule(payload)
          .then((res) => {
            state.commit("updateModule", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updateModule: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.updateModule(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    createModuleEntry: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.createModuleEntry(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getTradingBalance: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getTradingBalance(payload)
          .then((res) => {
            state.commit("updateTradingBalance", res.data.balance);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    requestPayout: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.requestPayout(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getPayoutStatus: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getPayoutStatus(payload)
          .then((res) => {
            state.commit("updatePayoutStatus", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getSubscriptions: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getSubscriptions(payload)
          .then((res) => {
            state.commit("updateSubscriptions", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    cancelSubscription: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.cancelSubscription(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    setSubscription: (state, payload) => state.commit("setSubscription", payload),
    getBusiness: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getBusiness(payload)
          .then((res) => {
            state.commit("updateBusiness", res.data.business);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getPartnerDesk: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getPartnerDesk(payload)
          .then((res) => {
            state.commit("updatePartnerDesk", res.data.desk);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getLeaderboard: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getLeaderboard(payload)
          .then((res) => {
            state.commit("updateLeaderboard", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updateConfig: (state, payload) => state.commit("updateConfig", payload),
    becomeAffiliate: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.becomeAffiliate(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getDesks: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.listDesks(payload)
          .then((res) => {
            state.commit("updateDesks", res.data.desks);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getDesk: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getDesk()
          .then((res) => {
            state.commit("updateDesk", res.data.desk);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    setDesk: (state, payload) => state.commit("setDesk", payload),
    updateUserModule: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.updateUserModule(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getOpenTickets: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getOpenTickets()
          .then((res) => {
            state.commit("updateTickets", res.data.tickets);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updateInteracted: (state, payload) => state.commit("updateInteracted", payload),
    addTradingAccount: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.addTradingAccount()
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    createPlayBook: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.createPlayBook(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getPlaybooks: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getPlaybooks(payload)
          .then((res) => {
            state.commit("updatePlaybooks", res.data.playbooks)
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getPlaybook: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getPlaybook(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updatePlayBook: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.updatePlayBook(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    uploadTradeDataToTradingAccount: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.uploadTradeDataToTradingAccount(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    createItem: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.createItem(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    listItems: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.listItems(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getItem: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getItem(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updateItem: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.updateItem(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getCertificates: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getCertificates(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    setCertificate: (state, payload) => state.commit("setCertificate", payload),
    getEvaluations: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getEvaluations(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    checkout: ({state,commit}, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.checkout(payload)
          .then((res) => {
            if (res.data.authToken) {
              commit("auth/authenticate", res.data.authToken, { root: true });
              commit("user/updateUser", res.data.user, { root: true });
            }
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    checkDiscountCode: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.checkDiscountCode(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getBalance: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getBalance(payload)
          .then((res) => {
            state.commit("updateBalance", res.data.balance);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getBalanceTransactions: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getBalanceTransactions(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    confirmOnboardingStep1: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.confirmOnboardingStep1(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    confirmCredit: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.confirmCredit(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getBrokerUser: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getBrokerUser(payload)
          .then((res) => {
            state.commit("updateBrokerUser", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getCheckout: (state, payload) =>
      new Promise((resolve, reject) => {
        DashboardApi.getCheckout(payload)
          .then((res) => {
            state.commit("updateCheckoutSession", res.data.checkout);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
  },
};

export default dashboard;
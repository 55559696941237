<template>
	<UiHeadline style="margin-bottom: 42px;">Members</UiHeadline>

	<UiHeadline size="m" style="margin-bottom: 16px;">Leaderboard</UiHeadline>

	<UiList style="max-width: 512px;" v-if="leaderboard.members">
		<router-link :to="`/settings/subscriptions/${member.id}`" v-for="member in leaderboard.members">
			<UiListItem>
				<div style="display: flex;justify-content: space-between;">
					<div>{{ member.firstname }}</div>
					<div style="color: #000;">{{ member.levelName }}</div>
				</div>
			</UiListItem>
		</router-link>
	</UiList>
</template>

<script setup>
	import { ref, computed } from "vue"
	import { useStore } from "vuex"

	const store = useStore()

	const leaderboard = computed(() => store.state.dashboard.leaderboard)

	store.dispatch("dashboard/getLeaderboard")
</script>